<template>
    <ButtonBack :assignClass="'card__btn-back'" />
    <div class="card__wrapper">
        <div class="card__title title-second">Восстановить пароль</div>
        <div class="card__description">Введите ваш e-mail, чтобы мы прислали на него новый пароль</div>
        <div class="card__form">

            <Input :type="'email'" :placeholder="'E-mail'" :isError="isError.email" v-model="email">
                <div v-if="isSendRequest">
                    <div class="input-group__message text-black">Пароль был отправлен на вашу почту.<br>Если он не пришел, попробуйте снова через</div>
                    <div class="input-group__message">
                        <TimeCounter :time="60" :classes="'strong'" @update:timer="stopTimer" />
                    </div>
                </div>
            </Input>

            <button class="btn btn-red" @click.prevent="sendRequest" :disabled="isSendRequest">Отправить</button>
        </div>
    </div>
</template>

<script>
import Input from "@/components/form/input"
import TimeCounter from "@/components/time-counter"

export default {
    components: { Input, TimeCounter },

    data() {
        return {
            isSendRequest: false,
            email: null,
            isError: {
                email: false
            }
        }
    },

    methods: {
        sendRequest() {
            this.$store.dispatch("AUTH_RESTORE_PASSWORD", { email: this.email })
                .then(() => this.isSendRequest = true)
                .catch(error => {
                    if (error.response.data.errors.email) this.isError.email = true
                })
        },
        stopTimer() {
            this.isSendRequest = false
        }
    },


}
</script>
